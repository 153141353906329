let colorsMain ={
  color0:'transparent',
  color1:"#ffffff",     
  color2:"#000000",
  color3:"#e34b9a",
  color4:"#1B1630",
  color5:'#02010C',
  color6:'#0D081E',
  color7:'#2f2b50',
  color8:'#e5e5e5',
  color9:'#064059',
  color10:'#999999',
  color11:'#333333',
  color12:'#1D334D',
  color13:'#e34b9a',   //colores diferente para 24e y m360
  color14:'rgba(255,255,255,0.4)',
  color15:'rgba(2,1,12,0.4)',
}

export {
  colorsMain
}
